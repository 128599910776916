import { useState, useEffect } from "react";
import { get, post } from "@/utils/request";
import { mergeDeep } from "@haici/utils";
import { useSupabaseSession } from "@/hooks/useAuth";
import { equals } from "@haici/utils";
import { useNavigate } from "react-router-dom";

export const useRequest = (api, params, allOptions = {}) => {
  const {
    manual = false,
    noCode = false,
    before,
    after,
    ...options
  } = allOptions;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [requestParams, setRequestParams] = useState(params);
  const token = useSupabaseSession()?.access_token;
  const navigate = useNavigate();

  let requestFunction;

  if (options.method === "post") {
    requestFunction = post;
  } else {
    requestFunction = get;
  }

  const run = async (runParams = {}) => {
    // before 要修改为可以修改请求参数，返回false就不请求
    if (typeof before === "function" && !before(runParams)) {
      console.log("before return false");
      return;
    }
    setLoading(true);
    try {
      let data = await requestFunction(
        api,
        runParams,
        mergeDeep(options, { headers: token ? { "x-token": token } : {} })
      );
      if (typeof after === "function") {
        data = await after(data);
      }
      setLoading(false);
      setData(data);
      return data;
    } catch (e) {
      navigate("/500", { state: { message: e.message } });
    }
  };
  const refresh = () => {
    run(requestParams).then();
  };

  useEffect(() => {
    if (!manual) {
      run(requestParams).then();
    }
  }, [requestParams]);

  useEffect(() => {
    if (!equals(requestParams, params)) {
      setRequestParams(params);
    }
  }, [api, params]);

  return {
    run,
    data,
    loading,
    refresh,
  };
};
