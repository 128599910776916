import React, { useState } from "react";
import Map, { MapProvider, useMap } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import Path from "./path";
import { useGetPathData } from "./hooks";
import MapInit from "./init";

const MAP_BOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoid2FsbHdhdGNoZXIiLCJhIjoiY2t6Z2Y4YXE0MDQ3czJ2cWtiYXU1OTY0ciJ9.wQHhLT-dZQH0RD9aAINXqg";
const MAP_STYLE = "mapbox://styles/mapbox/dark-v10";

const MapComponent = ({ geoPath }) => {
  const [map, setMap] = useState(null);
  const { geoData } = useGetPathData(geoPath, map);
  return (
    <div className="h-full w-full overflow-hidden absolute hidden md:block">
      <MapProvider>
        <MapInit setMap={setMap} />
        <Map
          id="baseMap"
          initialViewState={{
            // 112.94482882170121, 28.067920723679716
            // longitude: 112.94482882170121,
            // latitude: 28.067920723679716,
            longitude: 112.943292120471597,
            latitude: 28.197017731145024,
            zoom: 14,
          }}
          // mapStyle="mapbox://styles/mapbox/streets-v11"
          // mapStyle="mapbox://styles/mapbox/outdoors-v11"
          mapStyle={MAP_STYLE}
          mapboxAccessToken={MAP_BOX_ACCESS_TOKEN}
          scrollZoom={false}
          interactive={false}
          // baseApiUrl="https://vercel-hike.lovexy.today/mapbox"
          // mapLib={mapboxgl}
        >
          {geoData ? <Path data={geoData} map={map} /> : null}
        </Map>
      </MapProvider>
    </div>
  );
};

export default MapComponent;
