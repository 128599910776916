import { useEffect, useState, useContext } from "react";
import {
  ReactNodeViewRenderer,
  useEditor as useTiptapEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import BulletList from "@tiptap/extension-bullet-list";
import Image from "@tiptap/extension-image";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { Figcaption } from "../components/node/figcaption";
import CustomImageWrapper from "../components/image";
import useUpload from "./useUpload";
import useKeyboardCommand from "./useKeyboardCommand";
import { SAVE_TYPE } from "@/utils/constants";
import { handleUploadFile } from "./editorUtils";
import { useNavigate } from "react-router-dom";
import { EditorStatusContext } from "@/context/editorGlobalContext";

const CustomImage = Image.extend({
  // group: "block",
  // atom: true,
  draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer(CustomImageWrapper);
  },
});

// const handleNodeFile = (view, e, url) => {
//   const node = view.state.schema.nodes.image.create({ src: url });
//   const coordinates = view.posAtCoords({
//     left: e.clientX,
//     top: e.clientY,
//   });
//   const transaction = view.state.tr.insert(coordinates.pos, node);
//   view.dispatch(transaction);
//   setTimeout(() => {
//     node.attrs.src =
//       "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202007%2F09%2F20200709134922_cbldk.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659778449&t=83c39b95c68f10ef8031c4210d2c5c5e";
//     view.dispatch(
//       view.state.tr.replaceWith(coordinates.pos, node.nodeSize, node)
//     );
//   }, 3000);
// };

export const useEditor = ({
  content,
  selectedId,
  sourcePrefix,
  changeSaveType,
  onSaveHikingDetail,
  clearContent,
  changePathData,
  session,
}) => {
  const { editable: globalEditable } = useContext(EditorStatusContext);

  useEffect(() => {
    if (globalEditable !== editable) {
      setEditable(globalEditable);
    }
  }, [globalEditable]);

  // const [ready, setReady] = useState(false);
  const [editable, setEditable] = useState(false);
  const navigate = useNavigate();

  const onEditor = () => {
    if (session) {
      if (!editable) {
        changeSaveType(SAVE_TYPE.UPDATE);
        setEditable(true);
      }
    } else {
      navigate("/login", { replace: true });
    }
  };

  useKeyboardCommand([
    {
      ".": onEditor,
      "。": onEditor,
      Escape: () => {
        setEditable(false);
      },
    },
    {
      n: () => {
        if (!editable) {
          changeSaveType(SAVE_TYPE.NEW);
          clearContent();
          // 避免编辑状态被覆盖，等官方修复editable相关问题后尝试删除
          setTimeout(() => {
            setEditable(true);
          });
        }
      },
    },
  ]);

  // useChangeEditorStatus(() => {
  //   if (!editable) {
  //     setEditable(true);
  //   }
  //   // https://github.com/ueberdosis/tiptap/pull/2935
  //   // editor.setEditable(!editable);
  // });
  // useNewEditorCommand(() => {
  //   console.log(". .");
  // });
  // const addImage = (url) => {
  //   if (url) {
  //     editor.chain().focus().setImage({ src: url }).run();
  //   }
  // };
  const { onUpload } = useUpload(sourcePrefix);

  // const onDrop = (file) => {};

  // const uploadLoading = useDragging({
  //   labelRef: ref,
  //   onDrop,
  //   handleChanges: async (file) => {
  //     console.log("change", file);
  //     await onUpload(file);
  //     return true;
  //   },
  // });

  const CustomDocument = Document.extend({
    content: "heading block*",
  });

  const onKeyboardSave = async ({ editor }) => {
    setEditable(false);
    await onSaveHikingDetail(editor.getHTML(), editor.getJSON());
    // ？？？？
    // setEditable(false);
    return true;
  };

  const CustomBulletList = BulletList.extend({
    name: "custom-bullet-list",
    addKeyboardShortcuts() {
      return {
        "Cmd-s": onKeyboardSave,
        "Ctrl-s": onKeyboardSave,
        // Escape: ({ editor }) => {
        //   editor.commands.blur();
        //   return true;
        // },
      };
    },
  });

  const editor = useTiptapEditor(
    {
      editable,
      extensions: [
        CustomDocument,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        StarterKit.configure({
          document: false,
          dropcursor: {
            color: "#fff",
          },
        }),
        CustomImage,
        Figcaption,
        CustomBulletList,
      ],
      content,
      // onSelectionUpdate({ editor }) {
      //   if (editor.isActive("image")) {
      //     onClickImg(editor.getAttributes("image").src);
      //   }
      // },
      editorProps: {
        handleDOMEvents: {
          drop: async (view, e) => {
            e.preventDefault();
            e.stopPropagation();
            await handleUploadFile(view, e, {
              onUpload,
              changePathData,
            });

            return;
          },
        },
      },
    },
    [content, editable]
  );

  useEffect(() => {
    if (!editor) {
      return undefined;
    }
    editor.setEditable(editable);
    // 此方式不会触发自定义 Img render 等待官方修复 23 456 111
    // if (editable) {
    //   editor.commands.focus();
    // }
  }, [editor, editable]);
  useEffect(() => {
    if (editor) {
      setEditable(false);
    }
  }, [content]);

  return {
    editor,
    editable,
  };
};
