import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useLoadingText } from "@/hooks/base/useLoadingText";

const LoadingBox = ({
  children,
  loadingComponent: LoadingComponent,
  loading,
}) => {
  const [visible, setVisible] = useState(false);
  const { loadingText, refresh } = useLoadingText();
  useEffect(() => {
    if (loading) {
      refresh();
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 700);
    }
  }, [loading]);
  return (
    <>
      {visible ? (
        <div
          className={cn(
            "w-full h-full absolute indent-0 transition-opacity duration-700",
            {
              "opacity-100": loading,
            },
            {
              "opacity-0 overflow-hidden": !loading,
            }
          )}
        >
          <LoadingComponent text={loadingText} />
        </div>
      ) : null}
      <div
        className={cn(
          "w-full h-full transition-opacity duration-500",
          {
            "opacity-0 overflow-auto": loading,
          },
          {
            "opacity-100": !loading,
          }
        )}
      >
        {children}
      </div>
    </>
  );
};

export default LoadingBox;
