import React, { useEffect, useState } from "react";
import cn from "classnames";
import { BellSVG } from "@/svgs";

const Index = () => {
  const [count, setCount] = useState(-1);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (count > 0) {
      setTimeout(() => setCount(count - 1), 1000);
    } else {
      setVisible(false);
      document.dispatchEvent(new Event("serviceWorkerUpdated"));
    }
  }, [count]);
  useEffect(() => {
    document.addEventListener(
      "serviceWorkerUpdatedAlert",
      (event) => {
        setVisible(true);
        setCount(3);
      },
      false
    );
  });
  return (
    <div
      className={cn("fixed bottom-0 w-full z-10", {
        ["block"]: visible,
        ["hidden"]: !visible,
      })}
    >
      <div className="w-full md:w-4/5 m-auto text-white px-6 py-4 border-0 rounded relative mb-4 bg-zinc-600 ">
        <span className="text-xl inline-block mr-5 align-middle">
          <BellSVG />
        </span>
        <span className="inline-block align-middle mr-8">
          <b className="capitalize">网站有更新，{count} 秒后自动刷新</b>
        </span>
        <button
          className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
          onClick={() => setVisible(false)}
        >
          <span>×</span>
        </button>
      </div>
    </div>
  );
};

export default Index;
