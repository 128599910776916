import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";
import Layout from "./layout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Layout />);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    document.dispatchEvent(new Event("serviceWorkerUpdatedAlert"));
    document.addEventListener(
      "serviceWorkerUpdated",
      () => {
        const registrationWaiting = registration.waiting;
        if (registrationWaiting) {
          registrationWaiting.postMessage({ type: "SKIP_WAITING" });
          registrationWaiting.addEventListener("statechange", (e) => {
            if (e.target.state === "activated") {
              window.location.reload();
            }
          });
        }
      },
      false
    );
  },
});
