import { useEffect, useState } from "react";
import { supabase } from "@/utils/constants";

export const useSupabaseSession = () => {
  const [session, setSession] = useState(supabase.auth.session());

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setSession(session);
      }
    });
  }, []);

  return session;
};
