import { useEffect, useState } from "react";

const useWelcomeLoading = (list, content) => {
  const [welcomeLoading, setWelcomeLoading] = useState(true);
  const [timerLoading, setTimerLoading] = useState(true);

  useEffect(() => {
    if (welcomeLoading && list.length && content) {
      setWelcomeLoading(false);
    }
  }, [list.length, content]);
  useEffect(() => {
    const unmount = setTimeout(() => {
      setTimerLoading(false);
    }, 3000);
    return () => {
      clearTimeout(unmount);
    };
  }, []);
  return {
    welcomeLoading: welcomeLoading || timerLoading,
  };
};

export default useWelcomeLoading;
