import { loading as base64Loading } from "@/assets/base64";

export const handleUploadFile = async (
  view,
  e,
  { changePathData, onUpload }
) => {
  const eventFiles = e.dataTransfer.files;
  if (eventFiles && eventFiles.length > 0) {
    const file = eventFiles[0];
    const { name: fileName } = file;
    const fileType = file.name.split(".").pop().toLowerCase();

    let imgUrl = null;
    let coordinates = null;
    if (fileType !== "gpx") {
      if (fileType === "heic") {
        imgUrl = base64Loading;
      } else {
        imgUrl = URL.createObjectURL(file);
      }
      const node = view.state.schema.nodes.image.create({ src: imgUrl });
      coordinates = view.posAtCoords({
        left: e.clientX,
        top: e.clientY,
      });
      const transaction = view.state.tr.insert(coordinates.pos, node);
      view.dispatch(transaction);
    }

    const resData = await onUpload({
      file,
      fileType,
      fileName,
    });
    if (fileType === "gpx") {
      const { geo, gpx } = resData;
      changePathData({
        geoPath: geo.url,
        gpxPath: gpx.url,
      });
    } else {
      const imgNode = view.state.schema.nodes.image.create({
        src: resData.url,
      });
      // handleNodeFile(view, e, resData.url);
      // imgUrl = resData.url;
      // node.attrs.src = imgUrl;
      const nodeType = e.target.dataset.nodeType;
      const replaceWidth = coordinates.inside !== -1 && !nodeType ? 2 : 1;
      view.dispatch(
        view.state.tr.replaceWith(
          coordinates.pos,
          coordinates.pos + replaceWidth,
          imgNode
        )
      );
    }
  }
};
