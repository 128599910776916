import React, { useEffect, useContext } from "react";
import { useBoolean } from "@/hooks/base";
import { useMedia } from "react-use";
import cn from "classnames";
import packageData from "@/../package.json";
import ss from "./index.less";
import { useNavigate } from "react-router-dom";
import { EditorStatusContext } from "@/context/editorGlobalContext";

const TitleComponent = ({
  onSelect,
  selectedId,
  list,
  loading,
  session,
  onSave,
}) => {
  const [visible, { toggle, setFalse }] = useBoolean(false);
  const navigate = useNavigate();
  const { editable, setEditable } = useContext(EditorStatusContext);

  useEffect(() => {
    if (!selectedId && list.length) {
      onSelect(list[0].id);
    }
  }, [list]);
  const onClickMenu = (id) => {
    if (!loading) {
      onSelect(id);
      setFalse();
    }
  };

  const onClickEdit = () => {
    if (session) {
      if (!editable) {
        setEditable(true);
      } else {
        onSave();
        setEditable(false);
      }
    } else {
      navigate("/login", { replace: true });
    }
  };

  const isWide = useMedia("(min-width: 768px)");
  return (
    <header
      className={cn(ss.root, {
        [ss.mobile]: !isWide,
        [ss.active]: visible,
      })}
    >
      <div className="md:hidden flex justify-between">
        <button onClick={toggle} className="w-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            className="bi"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
          </svg>
        </button>
        <div className="md:hidden w-8 h-8" onClick={onClickEdit}></div>
      </div>
      <nav
        className={cn(
          ss.nav,
          {
            ["hidden"]: !visible,
          },
          {
            ["block"]: visible,
          },
          "md:block"
        )}
      >
        <ul>
          {list?.map((item) => (
            <li
              key={item.id}
              className={cn(
                "cursor-pointer hover:text-gray-100 border-b border-b-gray-300 text-base py-2",
                {
                  "text-base md:text-xl": `${item.id}` !== selectedId,
                },
                {
                  "font-bold text-gray-200 md:text-3xl":
                    `${item.id}` === selectedId,
                },
                { "cursor-not-allowed": loading },
                "md:border-0"
              )}
              onClick={() => onClickMenu(item.id)}
            >
              {item.title}
            </li>
          ))}
        </ul>
      </nav>
      <div
        className={cn(
          "text-zinc-100 text-base absolute top-4 right-4 opacity-10",
          {
            hidden: !visible,
          }
        )}
      >
        {packageData.version}
      </div>
    </header>
  );
};

export default TitleComponent;
