import { useEffect, useState } from "react";
import { get, post } from "@/utils/request";
import { useRequest } from "@/hooks/useRequest";
import useSWR, { mutate } from "swr";

const useGetHikeList = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHikeList = async () => {
    setLoading(true);
    const list = await get("/api/list");
    setList(list);
    setLoading(false);
  };

  useEffect(() => {
    getHikeList().then();
  }, []);
  //////////////////////////////////////////////////////////////////////////////////
  // const {
  //   run: getHikeList,
  //   loading,
  //   data: list = [],
  // } = useRequest("/api/list");
  //
  // console.log(list, loading);
  // const { data: list = [], loading, mutate } = useSWR("/api/list");
  // setTimeout(() => {
  //   mutate(() => post("/api/test", [{ a: "abc" }, { b: "def" }]));
  // }, 3000);

  return { list, loading, refresh: getHikeList };
};

export default useGetHikeList;
