import React from "react";
import { useMedia } from "react-use";
import EditorComponent from "../editor";
import LoadingBox from "@/components/loading/box";
import Loading from "@/components/loading/content";
import ss from "./index.less";
import { useEditor } from "../editor/hooks/useEditor";
import cn from "classnames";

const ContentComponent = React.forwardRef(
  (
    {
      selectedId,
      content,
      sourcePrefix,
      loading,
      onSaveHikingDetail,
      changeSaveType,
      changePathData,
      clearContent,
      session,
    },
    ref
  ) => {
    const { editor } = useEditor({
      content,
      selectedId,
      sourcePrefix,
      changeSaveType,
      onSaveHikingDetail,
      clearContent,
      changePathData,
      session,
    });

    const isWide = useMedia("(min-width: 768px)");

    React.useImperativeHandle(ref, () => ({
      save: async () => {
        await onSaveHikingDetail(editor.getHTML(), editor.getJSON());
      },
    }));

    if (!editor) return null;

    return (
      <div className={cn(ss.root, { [ss.mobile]: !isWide })}>
        <div className="w-full md:max-w-[85%] xl:max-w-[75%] 2xl:max-w-[65%] h-full relative">
          <LoadingBox loadingComponent={Loading} loading={loading}>
            <EditorComponent editor={editor} />
          </LoadingBox>
          {/*{loading ? <Loading /> : <EditorComponent editor={editor} />}*/}
        </div>
      </div>
    );
  }
);

export default ContentComponent;
