import { useState, useEffect, useRef, useMemo } from "react";
import { useRequest } from "@/hooks/useRequest";
import { SAVE_TYPE } from "../utils/constants";
import { delay } from "@haici/utils";
import dj from "dayjs";

const useHikeData = ({ refreshHikeList, selectedId, onChangeSelected }) => {
  const [content, setContent] = useState(null);
  const [pathData, setPathData] = useState({});
  const [sourcePrefix, setSourcePrefix] = useState(null);
  const pathDataRef = useRef(null);
  const [saveType, setSaveType] = useState(SAVE_TYPE.UPDATE);

  const {
    data: hikeDetail,
    loading: detailLoading,
    refresh: refreshDetail,
  } = useRequest(
    "/api/detail",
    { id: selectedId },
    {
      before: (params) => !!params.id,
      after: async (data) => {
        await delay(800);
        return data;
      },
    }
  );

  const { run: saveHikingDetail, loading: saveLoading } = useRequest(
    "/api/save",
    {},
    { method: "post", manual: true }
  );

  useEffect(() => {
    if (hikeDetail) {
      setContent(hikeDetail.content);
      setPathData({
        geoPath: hikeDetail.geoPath,
        gpxPath: hikeDetail.gpxPath,
      });
      setSourcePrefix(hikeDetail.sourcePrefix);
    } else {
      setContent(null);
    }
  }, [hikeDetail]);

  useEffect(() => {
    pathDataRef.current = pathData;
  }, [pathData]);

  const onSaveHikingDetail = async (htmlContent, jsonContent) => {
    const [titleContent, dateContent] = jsonContent.content;

    const reqData = {
      content: htmlContent,
      sourcePrefix,
      ...(pathDataRef.current || {}),
      title: titleContent.content[0].text,
      date: dj(dateContent.content[0].text, "YYYY/MM/DD").format("YYYY-MM-DD"),
    };

    if (saveType === SAVE_TYPE.UPDATE) {
      reqData.id = selectedId;
      await saveHikingDetail(reqData);
      // 改动点，是否需要重现获取详情数据？
      await refreshDetail();
      await refreshHikeList();
    } else if (saveType === SAVE_TYPE.NEW) {
      const { id } = await saveHikingDetail(reqData);
      await refreshHikeList();
      onChangeSelected(id);
    }
  };

  const clearContent = () => {
    setPathData({});
    setContent(null);
    setSourcePrefix(dj().format("YYYYMMDD"));
  };

  return {
    selectedId,
    content,
    pathData,
    sourcePrefix,
    onChangeSelected,
    setContent,
    setPathData,
    setSaveType,
    onSaveHikingDetail,
    clearContent,
    loading: detailLoading || saveLoading,
  };
};

export default useHikeData;
