import { NodeViewWrapper } from "@tiptap/react";
import React, { useState } from "react";
import { useMedia } from "react-use";
import cn from "classnames";
import { Controlled } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const parseImagePath = (path) => {
  if (!path.includes("blob") && !path.includes("base64")) {
    const imageType = path.split(".").pop().toLowerCase();
    switch (imageType) {
      case "heic":
        return `${path}?x-oss-process=image/format,jpeg/resize,l_1024`;
      default:
        return `${path}?x-oss-process=image/resize,l_1024`;
    }
  } else {
    return path;
  }
};

const ImageWrapper = (props) => {
  const { selected, editor } = props;
  const [isZoom, setIsZoom] = useState(false);
  const [horizontal, setHorizontal] = useState(true);
  const onZoomChange = (isZoom) => {
    if (isZoom) {
      setIsZoom(true);
    } else {
      setIsZoom(false);
    }
  };
  const onLoad = ({ target }) => {
    if (target.naturalHeight > target.naturalWidth) {
      setHorizontal(false);
    }
  };
  const imgSrc = parseImagePath(props.node.attrs.src);
  const isWide = useMedia("(min-width: 768px)");
  return (
    <NodeViewWrapper className="react-component-with-content">
      <div
        className="w-full md:max-w-[100%] lg:max-w-[85%] 2xl:max-w-[80%]"
        data-node-type="imageContainer"
      >
        {editor.isEditable ? (
          <img
            src={imgSrc}
            alt=""
            data-node-type="image"
            className={`${cn(
              {
                "outline-1 outline-gray-50 outline ": selected,
              },
              "max-w-[85%]"
            )}`}
          />
        ) : (
          <Controlled
            overlayBgColorStart="rgba(5, 23, 42, 0.1)"
            overlayBgColorEnd="rgba(5, 23, 42, .95)"
            zoomMargin={isWide && horizontal ? 200 : 20}
            isZoomed={isZoom}
            onZoomChange={onZoomChange}
          >
            <img
              onLoad={onLoad}
              src={imgSrc}
              alt=""
              className={cn("w-full lg:max-w-full h-full")}
            />
          </Controlled>
        )}
      </div>
    </NodeViewWrapper>
  );
};

export default ImageWrapper;
