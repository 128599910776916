import React from "react";
import { supabase } from "@/utils/constants";
import { GitHubSVG } from "@/svgs";
import { useSupabaseSession } from "@/hooks/useAuth";
import useSWR from "swr";
import ss from "./index.less";
import cn from "classnames";

const LoginPage = () => {
  const [loading, setLoading] = React.useState(false);
  const onLogin = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    await supabase.auth.signIn({
      provider: "github",
    });
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div
        className={cn("w-10 h-10 rounded-full overflow-hidden", ss.logo, {
          [ss.active]: loading,
        })}
      >
        <GitHubSVG
          className="w-full h-full cursor-pointer bg-black fill-zinc-400 hover:fill-zinc-50 transition transition-colors duration-300 ease-in-out"
          onClick={onLogin}
        />
      </div>
    </div>
  );
};

export default LoginPage;
