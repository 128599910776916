import React from "react";
import { useMedia } from "react-use";
import cn from "classnames";
import ss from "./index.less";

const Loading = () => {
  const isWide = useMedia("(min-width: 768px)");
  const text = "hiking";
  return (
    <div className={cn(ss.loader, { [ss.mobile]: !isWide })}>
      {text
        .toUpperCase()
        .split()
        .map((word, index) => (
          <span key={index}>{word}</span>
        ))}
    </div>
  );
};

export default React.memo(Loading, (prevProps, nextProps) => {
  return prevProps?.text === nextProps?.text;
});
