import React, { useState } from "react";

const loadingTextList = [
  "HIKEING",
  "Don’t ignore your dreams.",
  "Don’t work too much.",
  "Say what you think.",
  "Cultivate friendships.",
  "Be happy.",
];

export const useLoadingText = () => {
  const [loadingText, setLoadingText] = useState(loadingTextList[0]);

  const refresh = () => {
    setLoadingText(
      loadingTextList[Math.floor(Math.random() * loadingTextList.length)]
    );
  };

  return {
    refresh,
    loadingText,
  };
};
