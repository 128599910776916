import { useState } from "react";
import { upload } from "@/utils/request";
import qs from "query-string";

const useUpload = (sourcePrefix) => {
  const [loading, setLoading] = useState(false);
  const onUpload = async ({ file, fileType, fileName }) => {
    setLoading(true);
    const res = await upload(
      `/api/upload?${qs.stringify({ fileName, fileType, sourcePrefix })}`,
      file
    );
    setLoading(false);
    return {
      ...res,
      fileType,
    };
  };
  return {
    onUpload,
    loading,
  };
};

export default useUpload;
