import React from "react";
import { useLocation } from "react-router-dom";

const Exception = (props) => {
  const { state } = useLocation();
  return (
    <div className="h-full flex justify-center items-center flex-col text-white">
      <h1 className="text-xl">500</h1>
      <h2 className="text-base">{state?.message}</h2>
    </div>
  );
};

export default Exception;
