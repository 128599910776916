import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core";

export const inputRegex = /^\s*>\s$/;

export const Figcaption = Node.create({
  name: "figcaption",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: "block+",

  group: "block",

  defining: true,

  parseHTML() {
    return [{ tag: "figcaption" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "figcaption",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setFigcaption:
        () =>
        ({ commands }) => {
          return commands.wrapIn(this.name);
        },
      toggleFigcaption:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      unsetFigcaption:
        () =>
        ({ commands }) => {
          return commands.lift(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-b": () => this.editor.commands.toggleFigcaption(),
    };
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },
});
