import { useEffect } from "react";
import { useMap } from "react-map-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

const InitComponent = ({ setMap }) => {
  const { baseMap } = useMap();
  useEffect(() => {
    if (!baseMap) {
      return;
    }
    const languageControl = new MapboxLanguage({
      defaultLanguage: "zh-Hans",
    });
    baseMap.addControl(languageControl);
    setMap(baseMap);
  }, [baseMap, setMap]);
  return null;
};

export default InitComponent;
