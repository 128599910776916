import React from "react";
import ss from "./index.less";

function App({ text }) {
  return (
    <div className={ss.root}>
      <div className={ss.text}>{text}</div>
    </div>
  );
}

export default React.memo(App, () => true);
