import { useEffect } from "react";
import tinykeys from "tinykeys";

// const useChangeEditorStatus = (openEdited) => {
//   useEffect(() => {
//     const unsubscribe = tinykeys(window, {
//       ".": () => openEdited(),
//       "。": () => openEdited(),
//     });
//     return () => {
//       unsubscribe();
//     };
//   }, [openEdited]);
// };
//
// const useNewEditorCommand = (openEdited) => {
//   useEffect(() => {
//     const unsubscribe = tinykeys(window, {
//       n: () => openEdited(),
//     });
//     return () => {
//       unsubscribe();
//     };
//   }, [openEdited]);
// };

const useKeyboardCommand = (commands) => {
  useEffect(() => {
    const unsubscribes = commands.map((command) => tinykeys(window, command));
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [commands]);
};

// export { useChangeEditorStatus, useNewEditorCommand };
export default useKeyboardCommand;
