import React from "react";
import { EditorContent } from "@tiptap/react";
import cn from "classnames";
import Menu from "./components/menu";

import ss from "./index.less";

const EditorComponent = ({ editor }) => {
  if (!editor) return null;
  const editable = editor.isEditable;
  return (
    <div className={cn(ss.root, { [ss.editor]: editable })}>
      <Menu editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default EditorComponent;
