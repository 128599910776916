import { isTextSelection } from "@tiptap/core";
import { BubbleMenu } from "@tiptap/react";

const shouldShow = ({ view, state, from, to }) => {
  const { doc, selection } = state;
  const { empty } = selection;
  const isImage = selection?.node?.type?.name === "image";
  const isEmptyTextBlock =
    !doc.textBetween(from, to).length && isTextSelection(state.selection);
  if (!view.hasFocus() || empty || isEmptyTextBlock || isImage) {
    return false;
  }

  return true;
};

const MenuComponent = ({ editor }) => {
  const onBold = () => {
    editor.chain().focus().toggleBold().run();
  };
  const onTask = () => {
    editor.chain().focus().toggleTaskList().run();
  };

  const menus = [
    {
      type: "Bold",
      onClick: () => {
        editor.chain().focus().toggleBold().run();
      },
    },
    {
      type: "Task",
      onClick: () => {
        editor.chain().focus().toggleTaskList().run();
      },
    },
    {
      type: "Quote",
      onClick: () => {
        editor.chain().focus().toggleBlockquote().run();
      },
    },
    {
      type: "Figcaption",
      onClick: () => {
        editor.chain().focus().toggleFigcaption().run();
      },
    },
  ];
  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      className="flex bg-gray-500 px-2 rounded"
      shouldShow={shouldShow}
    >
      {menus.map(({ type, onClick }) => (
        <button
          key={type}
          onClick={onClick}
          className="border-none text-gray-50 text-sm mr-1"
        >
          {type}
        </button>
      ))}
    </BubbleMenu>
  );
};
export default MenuComponent;
