import { createClient } from "@supabase/supabase-js";
export const SAVE_TYPE = {
  NEW: "NEW",
  UPDATE: "UPDATE",
};

export const supabase = createClient(
  process.env.SUPABASE_URL,
  process.env.SUPABASE_KEY
);
