export const GitHubSVG = (props) => {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" version="1.1" {...props}>
      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
    </svg>
  );
};

export const BellSVG = () => (
  <svg width="30" height="35" xmlns="http://www.w3.org/2000/svg">
    <g>
      <title>Layer 1</title>
      <path
        fill="#ffffff"
        stroke="null"
        id="svg_1"
        d="m14.9987,2.45364c-1.04031,0 -1.88079,0.84048 -1.88079,1.88079l0,1.12848c-4.29056,0.86987 -7.52318,4.66672 -7.52318,9.21589l0,1.10497c0,2.76242 -1.0168,5.43079 -2.85058,7.49967l-0.43493,0.48783c-0.49371,0.55248 -0.61126,1.34594 -0.31151,2.02185s0.97566,1.11084 1.71623,1.11084l22.56953,0c0.74056,0 1.4106,-0.43493 1.71623,-1.11084s0.1822,-1.46937 -0.31151,-2.02185l-0.43493,-0.48783c-1.83377,-2.06887 -2.85058,-4.73137 -2.85058,-7.49967l0,-1.10497c0,-4.54917 -3.23262,-8.34603 -7.52318,-9.21589l0,-1.12848c0,-1.04031 -0.84048,-1.88079 -1.88079,-1.88079zm2.6625,28.99362c0.7053,-0.7053 1.09909,-1.66333 1.09909,-2.6625l-3.76159,0l-3.76159,0c0,0.99917 0.39379,1.9572 1.09909,2.6625s1.66333,1.09909 2.6625,1.09909s1.9572,-0.39379 2.6625,-1.09909z"
      />
    </g>
  </svg>
);
