import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Map from "../../components/map";
import Content from "../../components/content";
import Title from "../../components/title";
import useHikeData from "../../hooks/useHikeData";
import useGetHikeList from "../../hooks/useGetHikeList";
import WelcomeLoading from "@/components/loading/welcome";
import "./index.css";
import LoadingBox from "@/components/loading/box";
import useWelcomeLoading from "@/hooks/useWelcomeLoading";
import { useMedia } from "react-use";
import { useSupabaseSession } from "@/hooks/useAuth";
import { useRequest } from "@/hooks/useRequest";
import { useEditorGlobalConfig } from "@/hooks/useEditorGlobalConfig";
import { EditorStatusContext } from "@/context/editorGlobalContext";
import UpdateMessage from "@/components/alert/updatemessage";

const Index = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedId = searchParams.get("id");

  const onChangeSelected = (id) => {
    setSearchParams({ id });
  };

  // const [welcomeLoading, setWelcomeLoading] = useState(true);

  // const {
  //   list,
  //   // loading: listLoading,
  //   refresh: refreshHikeList,
  // } = useGetHikeList();

  const { data: list = [], refresh: refreshHikeList } = useRequest("/api/list");

  const {
    content,
    pathData,
    sourcePrefix,
    setPathData,
    setSaveType,
    onSaveHikingDetail,
    clearContent,
    // refresh,
    loading,
  } = useHikeData({
    refreshHikeList,
    selectedId,
    onChangeSelected,
  });

  const { editable, setEditable } = useEditorGlobalConfig();

  const session = useSupabaseSession();
  const contentRef = React.useRef(null);

  const { welcomeLoading } = useWelcomeLoading(list, content);
  const isWide = useMedia("(min-width: 768px)");

  return (
    <EditorStatusContext.Provider value={{ editable, setEditable }}>
      <LoadingBox loading={welcomeLoading} loadingComponent={WelcomeLoading}>
        <div className="h-full flex md:justify-between relative flex-col md:flex-row">
          <Title
            onSelect={onChangeSelected}
            selectedId={selectedId}
            list={list}
            loading={loading}
            session={session}
            onSave={() => {
              contentRef.current.save();
            }}
          />
          <Content
            selectedId={selectedId}
            loading={loading}
            content={content}
            sourcePrefix={sourcePrefix}
            ref={contentRef}
            // refresh={refresh}
            changePathData={setPathData}
            changeSaveType={setSaveType}
            onSaveHikingDetail={onSaveHikingDetail}
            clearContent={clearContent}
            session={session}
          />
          {isWide ? <Map geoPath={pathData.geoPath} /> : null}
        </div>
      </LoadingBox>
      <UpdateMessage />
    </EditorStatusContext.Provider>
  );
};

export default Index;
